import { useTournamentsStore } from '../stores/useTournamentsStore'

interface EnabledTournamentsReturn {
  isEnabledTournaments: ComputedRef<boolean>
}

export function useEnabledTournaments(): EnabledTournamentsReturn {
  const { isEnabledTournaments } = storeToRefs(useTournamentsStore())

  return {
    isEnabledTournaments,
  }
}
